import antdDeDE from "antd/es/locale/de_DE";
import antdEnUS from "antd/es/locale/en_US";
import { THEME_CONFIG } from "configs/AppConfig";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./locales/de_DE.json";
import en from "./locales/en_US.json";

export const resources = {
  en: {
    translation: en,
    antd: antdEnUS,
  },
  de: {
    translation: de,
    antd: antdDeDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: localStorage.getItem("i18nextLng") || THEME_CONFIG.locale,
  lng: localStorage.getItem("i18nextLng") || THEME_CONFIG.locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
