import React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { THEME_CONFIG } from "./configs/AppConfig";
import history from "./history";
import "./lang";
import Layouts from "./layouts";
import { store as myStore, persistor } from "./redux/store";
import store from "./store";
import "./style/index.scss";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
      <div className="App">
        <Provider store={myStore}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter history={history}>
                <ThemeSwitcherProvider
                  themeMap={themes}
                  defaultTheme={THEME_CONFIG.currentTheme}
                  insertionPoint="styles-insertion-point"
                >
                  <Layouts />
                </ThemeSwitcherProvider>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </Provider>
      </div>
  );
}

export default App;
