import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { store } from "../redux/store";
import { setLoading } from "../redux/view/actions";
import { baseURL } from "./AdminService";

export const middleField = (api) => {
  api.interceptors.response.use(
    (response) => {
      store.dispatch(setLoading(false));
      if (response?.data?.message) message.success(response.data.message);
      return response;
    },
    async (error) => {
      store.dispatch(setLoading(false));
      const { response } = error;

      if (response) {
        const { data } = response;
        if (data.message === "jwt expired") {
          if (response.config.url.includes("refresh")) {
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            window.location.href = "/";
          }
          const result = await axios.post(`${baseURL}/auth/refresh`, {
            accessToken: Cookies.get("accessToken"),
            refreshToken: Cookies.get("refreshToken"),
          });
          Cookies.set("accessToken", result.data.accessToken);
          Cookies.set("refreshToken", result.data.refreshToken);
          window.location.reload();
        }
        if (
          data &&
          data.message &&
          data.message !== "jwt expired" &&
          data.message !== "jwt malformed"
        ) {
          message.error(data.message);
        }
      }
      return Promise.reject(error);
    }
  );

  api.interceptors.request.use(
    async (config) => {
      store.dispatch(setLoading(true));

      if (
        ["login", "register", "refresh"].some((endpoint) =>
          config.url.includes(endpoint)
        )
      )
        return config;

      const access_token = Cookies.get("accessToken");
      const refresh_token = Cookies.get("refreshToken");

      return {
        ...config,
        params: {
          ...config.params,
          language: localStorage.getItem("i18nextLng"),
        },
        headers: {
          ...config.headers,
          access_token: access_token,
          refresh_token: refresh_token,
        },
      };
    },
    (error) => {
      store.dispatch(setLoading(false));
      return Promise.reject(error);
    }
  );
};
