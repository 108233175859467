import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { baseURL } from "./AdminService";
import { middleField } from "./middlefield";

class AuthService {
  constructor() {
    this.api = axios.create({
      baseURL: `${baseURL}/auth`,
    });
    middleField(this.api);
  }

  async registerUser({
    name,
    email,
    password,
    confirmPassword,
    locatedAt,
    roleAtCompany,
    company,
  }) {
    const result = await this.api.post("/register", {
      name,
      email,
      password,
      confirmPassword,
      locatedAt,
      roleAtCompany,
      company,
    });
    return result;
  }

  async loginUser({ email, password }) {
    const result = await this.api.post("/login", {
      email,
      password,
    });
    return result;
  }

  async refreshToken() {
    const result = await this.api.post("/refresh", {
      accessToken: Cookies.get("accessToken"),
      refreshToken: Cookies.get("refreshToken"),
    });
    return result;
  }

  async requestPasswordReset({ email }) {
    const result = await this.api.post("/requestPasswordReset", {
      email,
    });
    return result;
  }

  async resetPassword({ resetToken, newPassword, confirmPassword }) {
    const result = await this.api.post("/resetPassword", {
      resetToken,
      newPassword,
      confirmPassword,
    });
    return result;
  }
}

export default new AuthService();
