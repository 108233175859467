import axios, { AxiosInstance } from "axios";
import { baseURL } from "./AdminService";
import { middleField } from "./middlefield";

class UserService {
  constructor() {
    this.apiUser = axios.create({
      baseURL: baseURL,
    });
    middleField(this.apiUser);
  }

  async getMe() {
    const path = window.location.href;
    const isAuth =
      path.split("/").includes("auth") ||
      path.split("/").includes("home") ||
      path.split("/").includes("home?redirect=");
    if (isAuth) {
      const result = await axios.get(`${baseURL}/user/me`);
      return result;
    } else {
      const result = await this.apiUser.get(`${baseURL}/user/me`);
      return result;
    }
  }

  async listManufacturer() {
    const result = await this.apiUser.get(`/user/listManufacturer`);
    return result;
  }

  async listProductionTechnology() {
    const result = await this.apiUser.get(`/user/listProductionTechnology`);
    return result;
  }

  async searchUsers(query) {
    const result = await this.apiUser.get(`/user/searchUsers`, {
      params: {
        query,
      },
    });
    return result;
  }

  async leaveCompany() {
    const result = await this.apiUser.delete(`/user/leaveCompany`);
    return result;
  }
  async getSubscription(id) {
    const result = await this.apiUser.get(`/user/getSubscription,`, {
      params: {
        id,
      },
    });
    return result;
  }

  async cancelSubscription(id) {
    const result = await this.apiUser.delete(`/user/cancelSubscription`, {
      params: {
        id,
      },
    });
    return result;
  }

  async subscribe({
    price_id,
    machineId,
    ownerId,
    redirect = window.location.origin,
  }) {
    const result = await this.apiUser.post(`/user/subscribe`, {
      price_id,
      redirect,
      machineId,
      ownerId,
    });
    return result;
  }

  // machine subscription
  // async getMachineSubscription() {
  //   const result = await this.apiUser.get(`/msubscribe/get-subscription/:subscriptionId`);
  //   return result;
  // }

  // async findMachineSubscription({ ownerId, machineId }) {
  //   const result = await this.apiUser.get(`/msubscribe/find-subscription/`,{
  //      ownerId, machineId
  //   });
  //   return result;
  // }
  // async cancelMachineSubscription() {
  //   const result = await this.apiUser.delete(`/msubscribe/cancel-subscription`);
  //   return result;
  // }
  // async subscribMachine({ machinId, redirect, price_id, redirect = window.location.origin }) {
  //   const result = await this.apiUser.post(`/msubscribe/process-payment`, {
  //     price_id,
  //     redirect,
  //     machinId
  //   });
  //   return result;
  // }
  // async leaveCompany() {
  //   const result = await this.apiUser.delete(`/user/leaveCompany`);
  //   return result;
  // }
  // async getSubscription() {
  //   const result = await this.apiUser.get(`/user/getSubscription`);
  //   return result;
  // }
  // async cancelSubscription() {
  //   const result = await this.apiUser.delete(`/user/cancelSubscription`);
  //   return result;
  // }
  // async subscribe({ price_id,machinId, ownerId,redirect = window.location.origin }) {
  //   const result = await this.apiUser.post(`/user/subscribe`, {
  //     price_id,
  //     redirect,
  //     machinId,
  //     ownerId,
  //   });
  //   return result;
  // }

  async changePassword({ oldPassword, newPassword, confirmNewPassword }) {
    const result = await this.apiUser.post(`/user/changePassword`, {
      oldPassword,
      newPassword,
      confirmNewPassword,
    });
    return result;
  }

  async addTeamMember({ name, email, locatedAt, roleAtCompany, baseUrl }) {
    const result = await this.apiUser.post(`/user/addTeamMember`, {
      name,
      email,
      locatedAt,
      roleAtCompany,
      baseUrl,
    });
    return result;
  }
}

export default new UserService();
