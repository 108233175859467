import { LOGIN, LOGOUT, PURGE_AUTH, USERNAME } from "./actionTypes";

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USERNAME:
      return { ...state, username: action.username };
    case PURGE_AUTH:
      return { ...state, username: undefined };
    case LOGIN:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

export default reducer;
