import axios from "axios";
import { middleField } from "./middlefield";

export const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api"
    : "https://leaf-backend-mu.vercel.app/api";

class AdminService {
  constructor() {
    this.apiAdmin = axios.create({
      baseURL: `${baseURL}/admin`,
    });
    middleField(this.apiAdmin);
  }

  async listUsers(page, limit, search = "") {
    const result = await this.apiAdmin.get(`/listUsers`, {
      params: {
        page,
        limit,
        search,
      },
    });
    return result;
  }

  async updateUser(userId, data) {
    const result = await this.apiAdmin.put(
      `/updateUser?userId=${userId}`,
      data
    );
    return result;
  }

  async getMaterials() {
    const result = await this.apiAdmin.get(`/getMaterials`);
    return result;
  }

  async createMaterial(materialData) {
    const result = await this.apiAdmin.post(`/createMaterial`, materialData);
    return result;
  }

  async updateMaterial(materialId, materialData) {
    const result = await this.apiAdmin.put(
      `/updateMaterial?materialId=${materialId}`,
      materialData
    );
    return result;
  }

  async createCompany(companyData) {
    const result = await this.apiAdmin.post(`/createCompany`, companyData);
    return result;
  }

  async updateCompany(companyId, companyData) {
    const result = await this.apiAdmin.put(
      `/updateCompany?companyId=${companyId}`,
      companyData
    );
    return result;
  }

  async getCompanies(page, limit, search) {
    const result = await this.apiAdmin.get(`/getCompanies`, {
      params: {
        page,
        limit,
        search,
      },
    });
    return result;
  }

  async createProductionSite(productionSiteData) {
    const result = await this.apiAdmin.post(
      `/createProductionSite`,
      productionSiteData
    );
    return result;
  }

  async updateProductionSite(productionSiteId, productionSiteData) {
    const result = await this.apiAdmin.put(
      `/updateProductionSite?productionSiteId=${productionSiteId}`,
      productionSiteData
    );
    return result;
  }

  async getProductionSites(companyId) {
    const result = await this.apiAdmin.get(
      `/getProductionSites?companyId=${companyId}`
    );
    return result;
  }

  async createManufacturer(manufacturerData) {
    const result = await this.apiAdmin.post(
      `/createManufacturer`,
      manufacturerData
    );
    return result;
  }

  async updateManufacturer(manufacturerId, manufacturerData) {
    const result = await this.apiAdmin.put(
      `/updateManufacturer?manufacturerId=${manufacturerId}`,
      manufacturerData
    );
    return result;
  }

  async getManufacturers() {
    const result = await this.apiAdmin.get(`/getManufacturers`);
    return result;
  }

  async createProductionTechnology(productionTechnologyData) {
    const result = await this.apiAdmin.post(
      `/createProductionTechnology`,
      productionTechnologyData
    );
    return result;
  }

  async updateProductionTechnology(
    productionTechnologyId,
    productionTechnologyData
  ) {
    const result = await this.apiAdmin.put(
      `/updateProductionTechnology?productionTechnologyId=${productionTechnologyId}`,
      productionTechnologyData
    );
    return result;
  }

  async getProductionTechnologies() {
    const result = await this.apiAdmin.get(`/getProductionTechnologys`);
    return result;
  }

  async updateLegals(body) {
    const result = await this.apiAdmin.put(`/updateLegals`, body);
    return result;
  }
}

export default new AdminService();
