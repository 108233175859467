import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { darkTheme, lightTheme } from "configs/ThemeConfig";
import Cookies from "js-cookie";
import { resources } from "lang";
import React, { Suspense, lazy, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Routes from "routes";
import UserService from "services/UserService";
import useBodyClass from "utils/hooks/useBodyClass";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const token = Cookies.get("accessToken");
  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") {
      UserService.getMe().then((data) => {
        if (
          !data.data.company &&
          !window.location.pathname.includes("createcompany")
        )
          window.location.href = "/app/pages/createcompany";
      });
    }
  }, [location]);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
